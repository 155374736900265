export const tableData = [
   {
      name: 'xyz',
      currentBalance: '$230',
      invoiceId: 'P00001D03',
      createDate: '06/26/22',
      status: 'Unpaid',
      paidOn: '-',
      type: 'Package',
      amountDue: '$860',
      balanceCredit: '$860',
   },
   {
      name: 'xyz',
      currentBalance: '$230',
      invoiceId: 'P00001D03',
      createDate: '06/26/22',
      status: 'Paid',
      paidOn: '06/29/22',
      type: 'Package',
      amountDue: '$860',
      balanceCredit: '$860',
   },
   {
      name: 'xyz',
      currentBalance: '$230',
      invoiceId: 'P00001D03',
      createDate: '06/26/22',
      status: 'Cancelled',
      paidOn: '-',
      type: 'Package',
      amountDue: '$860',
      balanceCredit: '$860',
   },
]