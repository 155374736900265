export const desktop = 1024
export const roles = ['parent', 'student', 'tutor', 'admin']
export const phoneDropdown = ['+1']

export const timeZones = [
   'Asia/Kolkata',
   // 'America/New_York',
   'US/Alaska',
   'US/Central',
   'US/Eastern',
   'US/Hawaii',
   'US/Mountain',
   'US/Pacific',
]

export const subjects = [
   'American Literature', 'English', 'French', 'Latin', 'Writing / Composition', 'Algebra 1', 'Algebra 2', 'Geometry', 'Trigonometry', 'Statistics', 'Calculus', 'Biology', 'Chemistry', 'Physics', 'Economics', 'Geography',' US Government', 'US History', 'World History', 'Physical Education', 'Music', 'Drama', 'Photography', 'Comp Sci', 'Design', 'Business', 'Law', 'Psychology', 'Accounting'
]

export const times = [
   '12:00 AM',
   '12:30 AM',
   '1:00 AM',
   '1:30 AM',
   '2:00 AM',
   '2:30 AM',
   '3:00 AM',
   '3:30 AM',
   '4:00 AM',
   '4:30 AM',
   '5:00 AM',
   '5:30 AM',
   '6:00 AM',
   '6:30 AM',
   '7:00 AM',
   '7:30 AM',
   '8:00 AM',
   '8:30 AM',
   '9:00 AM',
   '9:30 AM',
   '10:00 AM',
   '10:30 AM',
   '11:00 AM',
   '11:30 AM',
   '12:00 PM',
   '12:30 PM',
   '1:00 PM',
   '1:30 PM',
   '2:00 PM',
   '2:30 PM',
   '3:00 PM',
   '3:30 PM',
   '4:00 PM',
   '4:30 PM',
   '5:00 PM',
   '5:30 PM',
   '6:00 PM',
   '6:30 PM',
   '7:00 PM',
   '7:30 PM',
   '8:00 PM',
   '8:30 PM',
   '9:00 PM',
   '9:30 PM',
   '10:00 PM',
   '10:30 PM',
   '11:00 PM',
   '11:30 PM',
]
export const grades = [6, 7, 8, 9, 10, 11, 12, 'College'];
