export const servicesSeeking = [
   {
      text: 'SAT / ACT Prep',
      checked: false
   },
   {
      text: 'Subject Tutoring',
      checked: false
   },
   {
      text: 'AP Tutoring',
      checked: false
   },
   {
      text: 'Homework Help',
      checked: false
   },
   {
      text: 'Concept Drills',
      checked: false
   },
   {
      text: 'SAT / ACT Crash Course',
      checked: false
   },
   {
      text: 'Others',
      checked: false
   }
]