
export const tempTableData = [
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
   {
      name: 'Joseph Brown 1',
      timeZone: 'IST',
      service: 'ACT/SAT',
      parent: 'Phil Brown',
      date: '07/05/22',
      score: 'E20 M26 R22 S30 | C24.5',
      status: 'Open',
   },
]