
export const tableData=[
   {
      name: 'Joseph Brown 1',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 2',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 3',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 4',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 5',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 6',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 7',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 8',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 9',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 10',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 11',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 12',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 13',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 14',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 15',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Joseph Brown 16',
      userType: 'Student',
      email: 'shashank000283@gmail.com',
      phone: '+01 1234567890',
      assignedTutor: 'Shivam Srivastava',
      leadStatus: 'Lead Status',
      tutorStatus: 'Tutor Status',
      services: 'ACT/SAT'
   },
   {
      name: 'Adsd',
      email: 'dsds'
   }
]

export const userTypesList = [
   'admin',
   'parent',
   'student',
   'tutor',
]